import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const FRANCHISES_FRANCHISE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/franchises',
        iconClass: 'fas fa-store',
        name: 'Franchisees',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing',
        order: 5,
        group: 'Franchisee Administration',

      },
    ]);
  };
}
