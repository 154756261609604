import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const JOB_TEMPLATES_JOB_TEMPLATE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/job-templates',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:JobTemplates',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.JobTemplates',
        invisible: true,
      },
    ]);
  };
}
