<section class="ttb-header-top d-flex flex-md-row flex-column justify-content-between px-1 py-1">
  <div>
    <span class="d-block text-md-start text-center">Tom's Tool Box</span>
  </div>
  <div *ngIf="isLoggedIn" class="d-flex flex-md-row flex-column gap-md-5">
    <span class="d-block text-md-end text-center">{{ location ?? '' }}</span>
    <span class="d-block text-md-end text-center">Hello, {{ firstName ?? '' }}</span>
    <a class="top-bar-logout-button align-self-center" (click)="logout()">Logout</a>
  </div>
</section>

<section class="ttb-header-bottom d-flex flex-md-row flex-column align-items-center px-3 py-1">
  <a href="/" title="Home">
    <img
      src="../../assets/images/logo/honey-do-service-logo.png"
      alt="Tom's Tool Box"
      height="60"
    />
  </a>
  <div id="ttb-header-bottom-title" class="w-100 fs-2 text-center">System Dashboard</div>
  <button
    class="top-bar-button me-2"
    type="button"
    id="top-bar-closing-ratio-report-button"
    (click)="routeClosingRatio()"
  >
    Closing Ratio Report
  </button>
  <button
    *ngIf="isAdmin"
    class="top-bar-button"
    id="top-bar-calendar-button"
    type="button"
    (click)="routeCalendar()">
    Calendar
  </button>
  <button
    class="top-bar-button"
    id="top-bar-customer-center-button"
    type="button"
    (click)="routeCustomers()">
    Customer Center
  </button>
</section>
