import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const FRANCHISEE_USERS_FRANCHISEE_USER_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/franchisee-users',
        iconClass: 'fas fa-users',
        name: 'Users',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing.Users',
        order: 6,
        group: 'Franchisee Administration',
      },
    ]);
  };
}
