import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const CLOSING_RATIO_REPORT_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/closing-ratio-report',
        iconClass: 'fas fa-chart-pie',
        name: 'Closing Ratio Report',
        requiredPolicy: 'TTB.Customers',
        layout: eLayoutType.application,
        order: 3,
        group: 'User Links',
      },
    ]);
  };
}
