import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PROCESSING_FEES_PROCESSING_FEE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/processing-fees',
        iconClass: 'fas fa-money-bill-wave',
        name: 'Processing Fee Rates',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing',
        order: 9,
        group: 'Franchisee Administration',
      },
    ]);
  };
}
