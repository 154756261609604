import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SALES_TAX_RATES_SALES_TAX_RATE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/sales-tax-rates',
        iconClass: 'fas fa-percent',
        name: '::Menu:SalesTaxRates',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing',
        order: 8,
        group: 'Franchisee Administration',
      },
    ]);
  };
}
