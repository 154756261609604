import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, PermissionGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then(m => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'gdpr-cookie-consent',
    loadChildren: () =>
      import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
        m => m.GdprCookieConsentModule
      ),
  },
  {
    path: 'franchises',
    loadChildren: () =>
      import('./franchises/franchise/franchise.module').then(m => m.FranchiseModule),
  },
  {
    path: 'sales-tax-rates',
    loadChildren: () =>
      import('./sales-tax-rates/sales-tax-rate/sales-tax-rate.module').then(
        m => m.SalesTaxRateModule
      ),
  },
  {
    path: 'client-sources',
    loadChildren: () =>
      import('./client-sources/client-source/client-source.module').then(m => m.ClientSourceModule),
  },
  {
    path: 'labor-rates',
    loadChildren: () =>
      import('./labor-rates/labor-rate/labor-rate.module').then(m => m.LaborRateModule),
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers/customer/customer.module').then(m => m.CustomerModule),
  },
  {
    path: 'franchisee-users',
    loadChildren: () =>
      import('./franchisee-users/franchisee-user/franchisee-user.module').then(
        m => m.FranchiseeUserModule
      ),
  },
  {
    path: 'processing-fees',
    loadChildren: () =>
      import('./processing-fees/processing-fee/processing-fee.module').then(
        m => m.ProcessingFeeModule
      ),
  },
  {
    path: 'job-templates',
    loadChildren: () =>
      import('./job-templates/job-template/job-template.module').then(m => m.JobTemplateModule),
  },
  {
    path: 'closing-ratio-report',
    loadChildren: () =>
      import('./closing-ratio-report/closing-ratio-report.module').then(
        m => m.ClosingRatioReportModule
      ),
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarsModule),
  },
  {
    path: 'redirect',
    loadChildren: () => import('./redirect/redirect.module').then(m => m.RedirectModule),
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
