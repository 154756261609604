import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'app-http-errors',
  templateUrl: './http-errors.component.html',
  styleUrls: ['./http-errors.component.scss'],
})
export class HttpErrorsComponent implements OnDestroy {
  @Input() messages: string[];
  @Output() destroyed = new EventEmitter<void>();

  constructor() {}

  ngOnDestroy(): void {
    this.destroyed.emit();
  }
}
