import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  FranchiseeUserService } from '@proxy/franchisee-users';

@Component({
  selector: 'app-ttb-header',
  templateUrl: './ttb-header.component.html',
  styles: [],
})
export class TtbHeaderComponent implements OnInit {
  public isLoggedIn = false;
  public firstName: string;
  public location: string;
  public isAdmin = false;

  constructor(private _config: ConfigStateService,
    private _authServ: AuthService,
    private _franchiseeUserService: FranchiseeUserService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this._config.getOne$('currentUser').subscribe(currentUser => {
      if (currentUser['isAuthenticated']) {
        this.isAdmin = !currentUser.roles.includes('Franchisee');
        this.isLoggedIn = true;
        this._franchiseeUserService.getWithNavigationProperties(currentUser.id).subscribe(result => {
          this.firstName = currentUser.name.split(' ')[0];
          let location = '';
          if (result?.franchise?.name)
          {
            location += `${result.franchise.name}, `;
          }
          if (result?.franchise?.state) {
            location += `${result.franchise.state}`;
          }
          this.location = location;
        });
      }
    });
  }

  logout() {
    this._config.getOne$('currentUser').subscribe(currentUser => {
      if (currentUser['isAuthenticated']) {
        this._authServ.logout().subscribe(() => {
          this.isLoggedIn = false;
        });
      }
    });
  }

  routeClosingRatio() {
    this.router.navigate(['/closing-ratio-report'])
  }

  routeCustomers() {
    this.router.navigate(['/customers'])
  }

  routeCalendar() {
    this.router.navigate(['/calendar'])
  }
}
