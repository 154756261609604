import { ABP, RoutesService, eLayoutType } from '@abp/ng.core';
import { eThemeSharedRouteNames } from '@abp/ng.theme.shared';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing',
        group: 'Home',
      },
    ]);
    const newAdminRouteConfig: Partial<ABP.Route> = {
      requiredPolicy: 'TTB.Routing',
    };
    routes.patch(eThemeSharedRouteNames.Administration, newAdminRouteConfig);
  };
}
