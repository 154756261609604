<div id="error-container" *ngIf="messages" class="alert alert-error">
  <div class="d-flex justify-content-between align-items-center">
    <ul class="list-unstyled m-0">
      <li *ngFor="let message of messages">{{ message }}</li>
    </ul>
    <p-button
      icon="pi pi-times"
      styleClass="p-button-rounded p-button-danger"
      (click)="messages = null"
    ></p-button>
  </div>
</div>
