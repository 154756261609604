import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import {
  ActivationEnd,
  ActivationStart,
  ChildActivationEnd,
  ChildActivationStart,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RoutesRecognized,
  Scroll,
} from '@angular/router';
import { Observable, Subscription, catchError, throwError } from 'rxjs';
import { HttpErrorsComponent } from './http-errors.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorsManagementService implements HttpInterceptor {
  private errorMessageComponentRef: any;
  private errorMessageSubscription: Subscription;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let messages: string[] = [];
        this.clearError();
        switch (error.status) {
          case 400:
            let validationErrors = error.error.error.validationErrors;
            if (validationErrors) {
              validationErrors.forEach(validationError => {
                messages.push(validationError.message);
              });
            } else {
              messages.push(
                "Bad Request: This error means there's something wrong with the way you've sent the request, like a typo or missing information. Please check your request and contact the administrator if the issue persists."
              );
            }
            break;
          case 401:
            messages.push(
              "Unauthorized: This error means you're not allowed to access the content you're trying to see. You might need to log in or provide some other kind of identification. If you believe you should have access, please contact the administrator."
            );
            break;
          case 403:
            const msg = error?.error?.error?.message;
            const code = error?.error?.error?.code;
            if (msg && code) {
              messages.push(msg);
            } else {
              messages.push(
                "Forbidden: This error means you're not allowed to access the content, even if you're authorized. This could be because you don't have the right permissions or the server is configured improperly. Please contact the administrator if you think you should have access."
              );
            }
            break;
          case 404:
            messages.push(
              "Not Found: This error means the content you're looking for isn't available. It could be because the page was deleted, the URL is incorrect, or the link is broken. If you think the content should be available, please contact the administrator."
            );
            break;
          case 500:
            messages.push(
              "Internal Server Error: This error means there's something wrong with the server and it can't process your request. It could be due to programming errors, server overload, or configuration issues. Please contact the administrator to report the issue."
            );
            break;
          case 503:
            messages.push(
              'Service Unavailable: This error means the server is temporarily unable to handle your request. It could be because of maintenance, high traffic, or other reasons. Please try again later or contact the administrator for assistance.'
            );
            break;
          case 504:
            messages.push(
              "Gateway Timeout: This error means the server acting as a gateway or proxy couldn't get a response from the upstream server. It could be due to network issues or server overload. Please contact the administrator if the issue persists."
            );
          default:
            messages.push(
              'An error occured. Please try again later or contact the administrator for assistance.'
            );
        }
        const factory = this.componentFactoryResolver.resolveComponentFactory(HttpErrorsComponent);
        const componentRef = factory.create(this.injector);
        componentRef.instance.messages = messages;
        this.appRef.attachView(componentRef.hostView);
        const element = document.getElementById('error-container');
        if (element) {
          element.appendChild(componentRef.location.nativeElement);
          this.errorMessageComponentRef = componentRef;
          this.errorMessageSubscription = componentRef.instance.destroyed.subscribe(() => {
            this.errorMessageComponentRef = null;
            this.errorMessageSubscription.unsubscribe();
          });
        }
        return throwError(error);
      })
    );
  }

  clearError() {
    if (this.errorMessageComponentRef) {
      this.errorMessageComponentRef.destroy();
      this.errorMessageComponentRef = null;
      this.errorMessageSubscription.unsubscribe();
    }
  }
}
