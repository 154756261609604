import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const CLIENT_SOURCES_CLIENT_SOURCE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/client-sources',
        iconClass: 'fas fa-sitemap',
        name: '::Menu:ClientSources',
        layout: eLayoutType.application,
        requiredPolicy: 'TTB.Routing',
        order: 4,
        group: 'Global Setup',
      },
    ]);
  };
}
